@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

/******************* CSS Variables *************************/
:root {
  /*== colors ==*/
  --main-blue: #77A6F7;
  --secondary-green: #00887a;
  --accent-blush: #FFCCBC;
  --undertone-blue: #d3e3fc;
  --undertone-green: #b7fbf5;
  --body-color: hsl(258, 60%, 98%);
  --text-color: #767478;
  --container-color: #ffffff;


  --puff-blue: #a2e1fa;
  --puff-red: #fcb3cf;
  --puff-blood-orange: #fbc5b1;
  --puff-orange: #fce6af;
  --puff-neon: #effab0;

  /*== fonts and sizing ==*/
  --body-font: 'Poppins',
    sans-serif;
  --h1-font-size: 2rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.2rem;
  --base-font-size: 1rem;
  --small-font-size: .75rem;
  --smaller-font-size: .6rem;

  /*== font weight ==*/
  --font-medium: 600;
  --font-semibold: 700;
  --font-bold: 900;

  /*== box shadow ==*/
  --shadow: 0px 5px 20px 0px rgb(69 67 96 / 10%);

  /*== border radius ==*/
  --border-radius: 20px;
}

/******************* Responsive *************************/
@media screen and (max-width: 1024px) {
  :root {
    --h1-font-size: 1.75rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --base-font-size: 0.8rem;
    --small-font-size: .813rem;
    --smaller-font-size: .75rem;
  }
}

/******************* Base *************************/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* color: var(--text-color); */
  font-family: 'Poppins',
    sans-serif;
}

html {
  scroll-behavior: smooth;
}

body,
button,
input,
textarea {
  font-family: var(--body-font);
  font-size: var(--base-font-size);
}

body {
  background-color: var(--body-color);
  line-height: 1.7;
  color: var(--text-color);
}

h2 {
  margin-bottom: 40px
}

h1,
h2,
h3 {
  color: var(--main-blue);
  font-weight: var(--font-bold);
  line-height: 1.2;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}